import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Media } from '@models/media';
import { Partner } from '@models/partner';
import { Lead, LeadRequest } from '@models/lead';
import { Candidate } from '@models/candidate';

@Injectable({
	providedIn: 'root',
})
export class FeedbackFormsService {
	private httpClient = inject(HttpClient);

	createMediaRequest(data: Media) {
		return this.httpClient.post<Media>('mailing/media/', data);
	}

	createPartnerRequest(data: Partner) {
		return this.httpClient.post<Partner>('mailing/partner/', data);
	}

	createLeadRequest(data: LeadRequest) {
		return this.httpClient.post<Lead>('mailing/lead/', data);
	}

	createCandidateRequest(data: Candidate) {
		return this.httpClient.post<Candidate>('mailing/candidate/', data);
	}
}
